module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://www.smarttie.ca/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false,"defaultNS":"translation","fallbackLng":"es"},"generateDefaultLanguagePage":false,"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-F1BLTQMVPK"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smarttie","short_name":"Smarttie","start_url":"/","background_color":"#f7f0eb","theme_color":"#E20C79","display":"standalone","icon":"src/images/smarttie-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58cd1f38a65fc1099e1ef1360b4b0a07"},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.services":"services","urls.careers":"careers","urls.privacy":"privacy","urls.ai-service":"generative-ai-services","urls.ai-development":"ai-development-services","urls.data-engineering":"data-engineering-services"}},"defaultLocale":"en","prefix":"urls."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
